.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: Arial, sans-serif;
}

.file-upload-label {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.file-upload-input {
  display: none;
  /* Hide the default file input */
}

.file-upload-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
}

.file-upload-button:hover {
  background-color: #45a049;
}

.file-upload-name {
  font-size: 14px;
  color: #555;
}

.file-upload-submit {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.file-upload-submit:hover {
  background-color: #0056b3;
}

.red-star {
  color: red;
}

.ops-label {
  margin-bottom: 0px !important;
  margin-left: 5px !important;
  white-space: unset !important;
  overflow: unset !important;
  text-overflow: unset !important;
  max-width: 100% !important;
  position: unset !important;
  transform: unset !important;
  font-size: 15px !important;
}

.ops-form-control .MuiInputBase-formControl{
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}

.approval{
  height: 15px;
  margin-left: 5px;
}