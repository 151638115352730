.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.msg-content {
  color: rgba(255, 99, 2, 0.82)
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
 /* // background-color: rgba(0,0,0,.03); */
  padding-top: 87%;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.5;
}
.color{
  color: black;
  background-color: red;
}
.alert-danger{
  color: #fff !important;
background-color: #e95441 !important;
/* background-color: #4aacc7 !important; */
border: #e95441 !important;
/* border: #4aacc7 !important;; */
z-index: 100000;
width: 100%;
text-align: left!important;
font-size: 14px;
/* font-weight: 700; */
padding: 1em;
position: fixed !important;
bottom: -16px;
}
.downtime{
  position: relative;
  right: -9px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.icon{
  float: left;
    left: 7px;
    position: absolute;
}

.banner-message{
  z-index: 1 !important;
  margin-bottom: 0px !important;
  position: absolute !important;
  bottom: 0px !important;
  width: 100% !important;
}