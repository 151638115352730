.room-box{
    height: 150px;
    background-color: #2546B1;
    border-radius: 15px;
    border-top-left-radius: 45px;
    padding: 15px 5px;
    margin-bottom: 1rem;
    min-height: 147px;
    .sec-title{
        color: #ffffff;
        line-height: 22px;
        font-weight: bold;
        text-align: left;
        margin: 5px 0px;
    }
    .sec-ellipsis{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .room-img {
        position: absolute;
       top: 4rem; 
        right: 1rem;
    }
    .status{
        font-size: 7pt;
        text-align: left;
        color: #ffffff;
    }
    .daily-round{
        height: auto;
    }
    .damen-orange{
        color: #FF7C52;
        font-size: 9pt;
    }
    .progress{
        height: 0.5rem;
        width: 75%;
    }
    img{
        float: left;
        margin: 10px 0px;
    }
    
}
.offline-span{
    position: fixed;
    bottom: 15pt;
    left: 50%;
    transform: translate(-50%, 0);
    color: #FF6302D1;
    width: 100%;
    text-align: center;
}
.sync-btn{
    position: fixed;
    bottom: 15pt;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #FF6302D1;
    width: 203pt;
    height: 49pt;
    border: none;
    border-radius: 40px;
    color: #fff;
    outline: none ;
    &:focus{
        outline: none ;
    }
    &:disabled {
opacity: .5;
    }
}

.query_loader {
    height: 100%;
    position: absolute;
    z-index: 10000;
    top: 50%;
    left: 43%;
}

@media only screen and (min-device-width: 768px) {
    
}