.search-field{
    width: 100%;
    max-width: 440px;
    margin-bottom: 10px;
}
.search{
    margin-bottom: 10px
}
.overflow-vessels{
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}