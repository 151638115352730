.service-details-wrapper {
    background: none;
    // height: 150px;

    .title {
        padding: 0px;
        height: 100px;
    }

    .title .welcome {
        font-size: 13pt;
        color: black;
        height: 70%;
        width: 100%;
        float: left;
        padding: 10px;
        background: white;
        border-radius: 2px;
        border: 1px solid #e5e2e2;

        .user-box {
            float: left;
            font-size: 20pt;
            height: 100%;
            width: 60pt;
            text-align: center;
            padding-top: 2px;

            img {
                height: 50px;
                border-radius: 35px;
                width: 50px;
            }
        }

    }

    .title .request-header {
        float: left;
        height: 30px;
        width: 100%;
        text-align: center;
    }

    .issue-description-wrapper {
        font-family: arial;
        height: 250px;

        .issue-container {
            width: 100%;
            margin: 0;

            .issue-description {
                width: 100%;
                text-align: left;
                padding-top: 10px;
                font-weight: bold;
                font-size: 13pt;
                float: left;
                width: 100%;
                height: 40px;
            }

            .part-specification {
                width: 100%;
                float: left;
                text-align: left;
                padding-top: 5px;
            }

            .issue-time-status {
                width: 100%;
                float: left;
                padding-top: 20px;
                font-size: 11pt;

                .issue-timestamp {
                    width: 50%;
                    float: left;
                    text-align: left;
                }

                .issue-status-description {
                    width: 50%;
                    float: left;
                    text-align: right;
                    color: red;
                }
            }

            .detailed-description {
                height: 140px;
                text-align: left;
                width: 100%;
                float: left;
                padding-top: 15px;
                padding-bottom: 5px;
                border-bottom: 1px solid #e5e2e2;

                .description-header {
                    font-weight: bold;
                }

                .description-details {
                    font-size: 10pt;
                }

                .description-images {
                    width: 100%;
                    height: 50px;
                    margin-top:-20px;
                    img {
                        padding-right: 20px;
                    }
                }
            }
        }
    }

    .workflow-wrapper {
        font-family: arial;
        height: 200px;

        .workflow-header {
            padding-top: 10px;
            height: 30px;
            text-align: left;
            font-size: 13pt;
            font-weight: bold;
        }

        .workflow-steps {
            height: 70px;
            width: 100%;

            .step-summary {
                width: 100%;
                height: 100%;

                .worflow-diagram {
                    height: 80px;
                    float: left;
                    width: 10%;
                    padding-top: 10px;

                    .workflow-round {
                        height: 20px;
                        width: 20px;
                        border: 1px solid black;
                        border-radius: 20px;
                    }

                    .workflow-arrow {
                        height: 43px;
                        margin-left: 11px;
                        margin-top: 7px;
                        width: 100%;
                        border-left: 1px solid black;
                    }
                }

                .step-details {
                    width: 90%;
                    height: 40px;
                    padding-top: 10px;
                    float: left;

                    .step-description {
                        width: 50%;
                        float: left;
                        text-align: left;
                        font-size: 12pt;
                    }

                    .step-timestamp {
                        width: 50%;
                        float: left;
                        text-align: right;
                        font-size: 11pt;
                        color: #938e8e;
                    }
                }

                .step-approval {
                    width: 90%;
                    height: 40px;
                    font-size: 11pt;
                    float: left;

                    .step-approver-details {
                        width: 60%;
                        float: left;
                        text-align: left;

                    }

                    .step-status {
                        width: 40%;
                        float: left;
                        text-align: right;
                    }

                    .pending {
                        color: red;
                    }
                }
            }
        }
    }

    .pending-steps-wrapper {
        width: 100%;
        height: 200px;
        font-family: arial;
        float: left;

        .task-header {
            font-size: 13pt;
            font-weight: bold;
            text-align: left;
            ;
            width: 100%;
            height: 30px;
        }

        .task-list-body {
            height: calc(100% - 10px);
            width: 100%;

            .task-list-details {
                float: left;
                width: 100%;
                padding-bottom: 10px;

                .workflow-round {
                    height: 20px;
                    width: 20px;
                    border: 1px solid black;
                    border-radius: 20px;
                    float: left;
                }

                .task-name {
                    width: 80%;
                    float: left;
                    text-align: left;
                    padding-left: 20px;
                }
            }
        }
    }
}

.approve-modal {
    .modal-dialog {
        background: none !important;

        .modal-content {
            top: 89%;
            margin-left: -8px;
            height: 100px;
            position: fixed;
            border-radius: 0px;
        }

        .approve-deny-button-group {
            padding-top: 10px;

            .deny-button {
                padding: 8px 33px;
                margin-right: 10px;
                color: black;
                background: white;
                border-radius: 2px;
                font-weight: lighter;
                font-family: arial;
            }

            .approve-button {
                padding: 8px 15px;
                border-radius: 2px;
                font-weight: lighter;
                font-family: arial;
            }
        }
    }
}

.comment-modal {
    .modal-dialog {
        background: none !important;

        .modal-content {
            top: 78%;
            margin-left: -8px;
            height: 200px;
            position: fixed;
            border-radius: 0px;

            .modal-body {
                padding-bottom: 0px;
            }

            .comment-button-group {
                width: 100%;
                text-align: center;
                .close-form {
                    padding: 8px 25px;
                    margin-right: 10px;
                    color: black;
                    background: white;
                    border-radius: 2px;
                    font-weight: lighter;
                    font-family: arial;
                }

                .submit-approval {
                    padding: 8px 22px;
                    border-radius: 2px;
                    font-weight: lighter;
                    font-family: arial;
                }
            }
        }
    }
}