.sensors-container {
  background-color: #224691;
  height: 100%;

  // padding-top: 5rem;
  .header {
    height: 20vh;
    color: #fff;
    text-align: left;
    position: relative;
  }

  .title {
    position: absolute;
    bottom: 3rem;
    margin-left: 2.5rem;
  }

  .react-simple-image-viewer__modal {
    z-index: 10000 !important;
  }

  .cust-loader {
    height: 45px;
    padding: 10px;
  }

  .fin-btnbv {
    background-color: #24D18B !important;
  }

  .overflow {
    height: 80vh;
    background-color: #fff;
    max-height: 80vh;
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    border-top: 1px solid #224691;
    // padding-top: 5rem;
  }

  .comp-image {
    height: 100px;
    width: auto;
    margin-top: 10px;
    display: block;
    margin-bottom: 20px;
    // background-image: url(../../assets/no-image-icon-15.png);
  }

  .sensor-list {
    min-height: 80pt;
    background-color: #ffffff;
    -webkit-box-shadow: 0 10px 12px -6px #f4f1f1;
    -moz-box-shadow: 0 10px 12px -6px #f4f1f1;
    box-shadow: 0 10px 12px -6px #f4f1f1;
    border: 1px solid #e9ecef;
    border-radius: 25px;
    // border-radius: 45px;
    padding: 15px;
    width: 95%;
    margin: 1rem auto;

    .list-box {
      line-height: 42pt;

      .user-box {
        background-color: #e9ecef;
        border-radius: 35px;
        font-size: 20pt;
        height: 45pt;
        width: 45pt;
        margin-top: 5px;

        img {
          height: 60px;
          border-radius: 35px;
          width: 60px;
        }
      }

      .name {
        line-height: 42pt;
        color: #868686;
        text-align: left;
        padding-top: 15px;

        .cust-name {
          text-align: left;
          color: #28458e;
          font-weight: bold;
          font-size: 16pt;
          margin-bottom: 0;
          //   white-space: nowrap;
          width: 200px;
          overflow: hidden;
          //    text-overflow: ellipsis;
        }
      }

      .check {
        display: none;
        position: absolute;
        right: -5px;
        top: -5px;
      }
    }

    .sensor-container {
      padding: 0.5rem;

      .sensor-name {
        text-align: left;
        color: #224691;
      }

      .sensor-form {
        text-align: initial;
      }
    }
  }

  .sensor-list:last-of-type {
    padding-bottom: 78px !important;
  }

  .sensor-info {
    padding-right: 6px;
    padding-top: 3px;
    font-size: 10pt;
  }

  .sensor-latest-details {
    color: rgba(255, 99, 2, 0.82);
    font-size: 9pt;
    /* width: 100%; */
    text-align: left;
    margin-bottom: 15px;
  }

  .calender-container {
    height: auto;
    background-color: #ffffff;
    -webkit-box-shadow: 0 10px 12px -6px #f4f1f1;
    -moz-box-shadow: 0 10px 12px -6px #f4f1f1;
    box-shadow: 0 10px 12px -6px #f4f1f1;
    border: 1px solid #e9ecef;
    border-radius: 26pt;
    // border-radius: 45px;
    position: absolute;
    top: 15%;
    z-index: 1000;
    width: 75%;
    left: 50%;
    transform: translate(-50%, 0);
    //  line-height: 65pt;
    padding: 0.1rem 1.5rem;
    overflow: hidden;

    .date-wrapper {

      // padding: 0.5rem;
      .date-box {
        //  float: left;
        // width: 44pt;
        height: 44pt;
        //  line-height: 44pt;
        background: #ff9a0224;
        border: 1px solid #ff9a0296;
        border-radius: 12pt;
        color: #ff9a02;
        padding-top: 0.3rem;
      }

      .date-text {
        padding-top: 0.4rem;
        color: #224691;
        text-align: left;
        position: relative;
        padding-left: 2rem;

        span {
          color: #868686;
        }

        .next-arrow {
          background-image: url("../../assets/right_arrow.svg");
          bottom: 18px;
          position: absolute;
          right: 1rem;
          height: 15px;
          width: 15px;
          float: right;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.tool-tip {
  position: absolute;
  right: 20px;
  z-index: 1;
  margin-top: 12px;
  color: #ffffff;
  background-color: orange;
  border-radius: 10px;
  height: 22px;
  width: 22px;
}

.tool {
  position: relative;
}

.customeTheme {
  opacity: 0.9 !important;

  // background-color: #dbdcdd !important;
  .dot {
    width: 20px;
    float: left;
  }

  .w-120 {
    width: 120px;
  }

  .red {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  .green {
    height: 10px;
    width: 10px;
    background-color: green;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  .blue {
    height: 10px;
    width: 10px;
    background-color: rgb(93, 229, 253);
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  .float-right {
    float: right;
    text-align: left;
  }
}

@media only screen and (min-device-width: 768px) {
  .sensors-container {
    max-width: 480px;
    margin: 0 auto;

    .calender-container {
      max-width: 380px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.finishedUser {
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  padding-right: 5px;
}

.btn-grp {
  margin-bottom: 3rem;
}

.add-comment-header {
  font-size: 17px;
  color: #224691;
  float: left;
  padding-left: 5px;
}

.clr {
  float: right;
  float: right;
  cursor: pointer;
  color: rgb(148 165 202);
  margin-right: 0rem;
}

.add-cmt-container {
  height: 250px;
  background-color: #ffffff;
  box-shadow: 0 10px 12px -6px #c1bebe;
  border: 1px solid #dee0e2;
  border-radius: 25px;
  padding: 12px;
  width: 95%;
  margin: 1rem auto;
}

.add {
  display: block;
  float: right;
  background: rgba(255, 99, 2, 0.82);
  border: 1px solid;
  color: rgb(255, 255, 255);
  display: block;
  margin-left: 0px;
  padding: 2px;
  margin-top: 11px;
  width: 75px;
  border-radius: 12px;
  //cursor: pointer;
}

textarea {
  border-radius: 12px;
  border: 1px solid #d4cccc;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 57pt;
  width: 99%;
  color: rgba(0, 0, 0, 0.54);
  resize: none !important;
}

textarea:focus-visible {
  border-radius: 12px !important;
  border: 1px solid #d4cccc !important;
  outline: none !important;
  resize: none !important;
}

.add-icon {
  font-size: 24px;
  color: orange;
  width: 30px;
  height: 38px;
  float: left;
  transform: "translate(-50%, 0px)";
  cursor: pointer;
}

.warning {
  float: left;
  color: #ff3b3b;
  font-size: 13px;
  margin-left: 1px;
}

.addIcon {
  position: absolute;
  left: 62%;
  z-index: 1;
  top: 5px;

  @media only screen and (max-width: 600px) {
    left: 7% !important;
    cursor: pointer !important;
  }

  @media only screen and (min-width: 768px) {
    left: 6% !important;
    cursor: pointer !important;
  }

  @media only screen and (min-width: 992px) {
    left: 6% !important;
    cursor: pointer !important;
  }

  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    left: 6% !important;
    cursor: pointer !important;
  }

  @media only screen and (max-width: 768px) and (min-width: 1024px) {
    left: 6% !important;
    cursor: pointer !important;
  }
}

.disableAdd {
  cursor: none !important;
  opacity: 0.5 !important;
  pointer-events: none;
}

.clickedIcon .add-icon {
  color: green;
}

.disableSensorList {
  display: none;
}

.live-val {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.time-label {
  float: left;
  padding-left: 30px;
  line-height: 45px;
  color: #6c757d;
}

.file-loader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #e9ecef;
  opacity: 0.5;
  z-index: 1000;
}

.timezones {
  width: 45%;
  text-align: left;
  float: right;
  margin-right: 30px;
}

.approval-info{
  height: 12px;
  margin-left: -10px;
}