.service-details-wrapper {
    background: none;
    // height: 150px;

    .title {
        padding: 0px;
        height: 100px;
    }

    .title .welcome {
        font-size: 13pt;
        color: black;
        height: 70%;
        width: 100%;
        float: left;
        padding: 10px;
        background: white;
        border-radius: 2px;
        border: 1px solid #e5e2e2;

        .user-box {
            float: left;
            font-size: 20pt;
            height: 100%;
            width: 60pt;
            text-align: center;
            padding-top: 2px;

            img {
                height: 50px;
                border-radius: 35px;
                width: 50px;
            }
        }

    }

    .title .request-header {
        float: left;
        height: 30px;
        width: 100%;
        text-align: center;
    }

    .issue-description-wrapper {
        font-family: arial;
        height: 250px;

        .issue-container {
            width: 100%;
            margin: 0;

            .issue-description {
                width: 100%;
                text-align: left;
                padding-top: 10px;
                font-weight: bold;
                font-size: 13pt;
                float: left;
                width: 100%;
                height: 40px;
            }

            .part-specification {
                width: 100%;
                float: left;
                text-align: left;
                padding-top: 5px;
            }

            .issue-time-status {
                width: 100%;
                float: left;
                padding-top: 20px;
                font-size: 11pt;

                .issue-timestamp {
                    width: 50%;
                    float: left;
                    text-align: left;
                }

                .issue-status-description {
                    width: 50%;
                    float: left;
                    text-align: right;
                    color: red;
                }
            }

            .detailed-description {
                height: 140px;
                text-align: left;
                width: 100%;
                float: left;
                padding-top: 15px;
                padding-bottom: 5px;

                .description-header {
                    font-weight: bold;
                }

                .description-details {
                    font-size: 10pt;
                }

                .description-images-complete {
                    width: 100%;
                    height: 50px;
                    margin-top:-20px;
                    img {
                        padding-right: 20px;
                    }
                }
            }
        }
    }

    .work-tab-selection {
        height: 44px;
        width: 100%;
        float: left;

        .nav-link {
            padding: 0.45rem 0.84rem;
            color: black;
            background: #e5e2e2;
        }

        .nav-tabs .nav-link.active {
            color: #f8f9fa;
            background-color: #868686;
            border-color: #dee2e6;
        }

        .workflow-wrapper {
            font-family: arial;
            height: 200px;
            float: left;

            .workflow-header {
                padding-top: 10px;
                height: 30px;
                text-align: left;
                font-size: 13pt;
                font-weight: bold;
            }

            .workflow-steps {
                height: 60px;
                width: 100%;

                .step-summary {
                    width: 100%;
                    height: 100%;
                    margin-bottom: 10px;

                    .worflow-diagram {
                        height: 60px;
                        float: left;
                        width: 7%;
                        // padding-top: 10px;

                        .workflow-round {
                            height: 20px;
                            width: 20px;
                            border: 1px solid black;
                            border-radius: 20px;
                        }

                        .workflow-arrow {
                            height: 43px;
                            margin-left: 11px;
                            margin-top: 7px;
                            width: 100%;
                            border-left: 1px solid black;
                        }
                    }

                    .step-details {
                        width: 92%;
                        height: 30px;
                        padding-top: 10px;
                        float: left;

                        .step-description {
                            width: 54%;
                            float: left;
                            text-align: left;
                            font-size: 11pt;
                        }

                        .step-timestamp {
                            width: 46%;
                            float: left;
                            text-align: right;
                            font-size: 11pt;
                            color: #938e8e;
                        }
                    }

                    .step-approval {
                        width: 90%;
                        height: 30px;
                        font-size: 11pt;
                        float: left;
                        padding-top: 10px;

                        .step-approver-details {
                            width: 60%;
                            float: left;
                            text-align: left;

                        }

                        .step-status {
                            width: 40%;
                            float: left;
                            text-align: right;
                        }

                        .pending {
                            color: red;
                        }
                    }
                }
            }
        }

        .work-report-wrapper {
            height: 250px;
            border: 1px solid #80808078;
            padding: 10px;
            border-radius: 5px;
            margin-top: 10px;

            .step-details {
                width: 92%;
                height: 165px;
                padding-top: 10px;
                float: left;

                .title-header {
                    width: 100%;
                    float: left;
                    text-align: left;
                }

                .step-title {
                    width: 100%;
                    float: left;
                    text-align: left;
                    font-size: 11pt;
                    color: #938e8e;
                    padding-bottom: 10px;
                }

                .description-header {
                    width: 100%;
                    float: left;
                    text-align: left;
                }

                .step-description {
                    width: 100%;
                    float: left;
                    text-align: left;
                    font-size: 11pt;
                    color: #938e8e;
                    padding-bottom: 10px;
                    
                }
                .description-images-pending {
                    width: 100%;
                    height: 50px;
                    float:left;
                    text-align: left;
                    img {
                        padding-right: 20px;
                    }
                }
            }

            .step-approval {
                width: 90%;
                height: 60px;
                font-size: 12pt;
                float: left;
                padding-top: 20px;

                .step-approver-details {
                    width: 100%;
                    float: left;
                    text-align: left;
                    font-size: 11pt;
                    color: #938e8e;
                }

                .step-status {
                    width: 100%;
                    float: left;
                    text-align: left;
                }

                .pending {
                    color: red;
                }
            }
        }

        .comment-wrapper {
            height: 500px;

            .comment-section {
                height: 120px;
                width: 100%;
                border: 1px solid #80808078;
                padding: 10px;
                border-radius: 7px;
                margin-top: 10px;

                .comment-description {
                    float: left;
                    text-align: left;
                    font-style: italic;
                    font-family: arial;
                }

                .comment-owner {
                    float:left;
                    width:100%;
                    text-align: left;
                }
                .comment-timestamp{
                    float:left;
                    width:100%;
                    font-family: arial;
                    text-align: left;
                }
            }
        }
    }


}