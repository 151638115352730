@font-face {
  font-family: "Damen-Font";
  src: local("Damen-Font"), url(./fonts/font.woff) format("woff");
}

body {
  margin: 0;
  font-family: Damen-font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

/* code {
  font-family: Damen-font
    monospace;
} */

.blocker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2546b1;
  color: #fff;
}
.blocker p {
  position: relative;
  top: 50%;
}

.toast-wrap .toast {
  position: absolute;
  right: 20px;
  width: 90%;
  bottom: 10px;
  background-color: #495057;
  color: #fff;
}

.toast-wrap .toast-header {
  background-color: #495057;
  color: #fff;
}

.unauthorized {
  text-align: center;
  background-color: #2546b1;
  color: #fff;
  height: 100vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-position-x: left;
  padding-top: 25%;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
}
.react-confirm-alert-overlay {
  background-color: rgb(175 167 167 / 90%) !important;
}
.react-confirm-alert-body {
  width: 350px !important;
  padding: 10px !important;
}
.react-confirm-alert-body h1 {
  font-weight: bold !important;
  color: #2546b1 !important;
}
.react-confirm-alert-button-group button {
  background-color: #2546b1 !important;
}
.react-confirm-alert-button-group {
  justify-content: center !important;
}
.modal-dialog {
  position: relative;
  width: 100% !important;
  padding: 0.5rem !important;
  margin: 0px !important;
  pointer-events: none;
  background: #a1accd;
  min-height: 100% !important;
}

.modal-body div {
  text-align: center;
}
/*Update Modal CSS*/

.update-mask {
  background-color: rgba(0, 0, 0, 0.72) !important;
}

.update-modal {
  max-width: 90% !important;
}

.update-modal .p-dialog-content {
  padding-top: 10px !important;
}
