$sidebar-bg-color: #224691;
@import "~react-pro-sidebar/dist/scss/styles.scss";
.pro-sidebar {
  position: absolute !important;
}
.menuIcon {
  font-size: 30px;
  color: #224691;
  position: absolute;
  right: 5px;
  cursor: pointer;
  z-index: 1007;
  // background-color: #efefef;
  margin-top: 10px;
  border-radius: 10px;
}
.backIcon {
  font-size: 30px;
  color: #224691;
  position: absolute;
  left: 5px;
  cursor: pointer;
  z-index: 1007;
  // background-color: #efefef;
  border-radius: 10px;
  margin-top: 10px;
}
.right-bar {
  right: 0 !important;
}
.right-bar-head{
  padding-left: 30px;
}
.modal-window {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-color: black;
  z-index: 1008;
}
.close-btn {
  float: right;
  font-size: 20px;
  margin-right: 10px;
  color: #fff;
  margin-top: 5px;
}
.menu {
  color: #ffffff;
  margin-top: 40%;
  margin-left: 25px;
  .icon {
    font-size: 30px;
  }
}
.head {
  padding-top: 50px;
  width: fit-content;
  height: 50%;
  margin: 0 auto;
  margin-bottom: 20px !important;
  .logo {
    float: left;
    margin-right: 7px;
    padding-top: 6px;
    width: 75pt !important;
  }
  .title {
    color: #fff;
    font-size: 25pt;
    text-align: left;
    display: inline-block;
  }
}
.dashboard {
  text-align: center;
  background-image: url("../../assets/list-bg.png");
  height: 97vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.overflow {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
}
.overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
  display: none;
}

.overflow::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c5c5c5;
}
.customers {
  padding: 20px;

  h5 {
    text-align: center;
    color: #2546b1;
    font-size: 17pt;
  }

  .title {
    padding-top: 20px;
    text-align: left;

    .welcome {
      font-size: 16pt;
      color: #868686;

      strong {
        text-transform: capitalize;
        color: #2546b1;
      }
    }

    .question {
      color: #868686;
      font-size: 12pt;
    }
  }

  .cust-list {
    min-height: 80pt;
    background-color: #ffffff;
    -webkit-box-shadow: 0 10px 12px -6px #f4f1f1;
    -moz-box-shadow: 0 10px 12px -6px #f4f1f1;
    box-shadow: 0 10px 12px -6px #f4f1f1;
    border: 1px solid #e9ecef;
    border-radius: 15px;
    border-top-left-radius: 45px;
    padding: 10px;
    margin-bottom: 15px;
    .list-box {
      line-height: 42pt;
      .user-box {
        background-color: #e9ecef;
        border-radius: 35px;
        font-size: 20pt;
        height: 45pt;
        width: 45pt;
        margin-top: 5px;
        img {
          height: 60px;
          border-radius: 35px;
          width: 60px;
        }
      }
      .name {
        line-height: 42pt;
        color: #868686;
        text-align: left;
        padding-top: 15px;
        .cust-name {
          text-align: left;
          color: #28458e;
          font-weight: bold;
          font-size: 16pt;
          margin-bottom: 0;
          //   white-space: nowrap;
          width: 200px;
          overflow: hidden;
          //    text-overflow: ellipsis;
        }
      }
      .check {
        display: none;
        position: absolute;
        right: -5px;
        top: -5px;
      }
    }
  }
}

.download-loader {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 124444;
  background-color: #fff;
  opacity: 0.7;
  .wrapper{
    position: relative;
    top: 50%;
  }
}

.pin-model .modal-dialog {
  margin: 0 auto !important;
  color: #224691;
}
@media only screen and (min-device-width: 768px) {
  .customers {
    max-width: 480px;
    margin: 0 auto;
  }
  .menuIcon {
    color: #224691 !important;
  }
  .backIcon {
    color: #224691 !important;
  }
}
.confirm-overlay .react-confirm-alert {
  h1 {
    font-size: 20px;
  }
}
.file-loader{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #e9ecef;
  opacity: 0.5;
  z-index: 1000;
}
