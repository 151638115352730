.room-box {
  height: auto;
  background-color: #2546b1;
  border-radius: 15px;
  border-top-left-radius: 45px;
  padding: 15px 5px;
  .sec-title {
    color: #ffffff;
    line-height: 22px;
    font-weight: bold;
  }
  .daily-round {
    height: auto;
  }
  .damen-orange {
    color: #ff7c52;
    font-size: 9pt;
    text-align: left;
  }
  .ship-icon {
    font-size: 2em;
    color: white;
    margin-top: 30px;
  }
  .cap-icon {
    font-size: 1.6em;
    color: white;
    margin-top: 30px;
  }
  .eng-icon {
    font-size: 2em;
    color: white;
    margin-top: 30px;
  }
  .service-request {
    margin-left: 7px;
    font-size: 2.3em;
    color: #f8f9fa;
    margin-top: 10px;
  }
}
