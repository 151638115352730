.new-request-container {
  padding-top: 20px;
  padding-left: 20px;
  width: 95%;
  font-family: "DAMEN-FONT";

  .new-request-form {
    .form-control {
      border: 1px solid #868686 !important;
    }
    .form-header {
      padding-bottom: 20px;
      font-size: 15pt;
      font-weight: bold;
      color: #2546b1;
    }

    .form-label {
      width: 100%;
      text-align: left;
      float: left;
      color: #2546b1;
    }

    .button-container {
      width: 100%;
      background: #2546b1;
      left: 0px;
      border: 1px solid transparent !important;
      text-align: center;
      text-transform: none !important;
      padding: 5px;
      border-radius: 5px;
      Button {
        background: transparent !important;
        border: transparent !important;
      }
      .button-text {
        text-transform: none !important;
        color: white !important;
      }
    }
    .button-group {
      padding-top: 30px;
      Button {
        border: transparent !important;
        color: white;
        border-radius: 5px !important;
      }
      Button.cancel-button {
        border: transparent;
        background: #ff7c52 !important;
        padding: 10px 29px;
      }
      Button.send-button {
        margin-left: 20px !important;
        border: transparent;
        background-color: #2546b1;
        padding: 10px 31px;
      }
    }

    .p-dropdown {
      width: 100% !important;
      height: 45px !important;
    }
  }

  .error-msg {
    color: red;
    font-family: Arial;
    font-weight: 100;
    float:left;
    width:100%;
    text-align: left;
  }
  
  .error-highlight {
    border: 1px solid red !important;
  }
}
