.image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 12345;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  width: 100%;
}

.css-vqh9lr-MuiSlider-root {
  width: 50% !important;
}

.slider {
  padding: 22px 0px;
}

.wid-250 {
  width: 270px;
}
.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(80vh - 140px);
  width: 100%;
  background: #f1f1f1;
  padding: 20px;
  box-sizing: border-box;
  padding-bottom: 0px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}

.chat-message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}
.send-icon {
  cursor: pointer;
  margin-right: 0px;
  color: green;
  width: 30px;
  font-size: 24px;
}

.message-bubble {
  background: #224691;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 5px;
  max-width: 60%;
  word-wrap: break-word;
}

.blur-img {
  img {
    height: 130px;
    border-radius: 15px;
    width: -webkit-fill-available;
  }
}

.uploaded-image {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  margin-bottom: 5px;
}

.cropper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 200px;
  background: #333;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.slider {
  width: 80%;
}

.chat-input {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 2px;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: relative;
  bottom: 10px;
  width: 100%;
}

.message-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 20px;
  margin-right: 10px;
}

.file-input {
  display: none;
}

.camera-icon {
  font-size: 24px;
  cursor: pointer;
  margin-right: 0px;
  color: #607299;
}

.send-button {
  background: #224691;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.image-card {
  padding: 10px;
  svg {
    font-size: 20px;
    color: #224691;
  }
}

.crop-button {
  font-size: 45px;
  color: green;
  cursor: pointer;
}

.cancel-button {
  font-size: 45px;
  color: rgb(199, 117, 24);
  cursor: pointer;
}

.chat-loader {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #333;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.chat-info {
  color: #ffe072;
  font-size: 10px;
}
