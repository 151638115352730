.service-request-wrapper {
    background: #e7e3e3;
    height: 150px;

    .title {
        padding: 0px;
        height: 95px;
    }

    .title .welcome {
        font-size: 13pt;
        color: black;
        height: 60%;
        width: 100%;
        float: left;

        .user-box {
            float: left;
            font-size: 20pt;
            height: 100%;
            width: 50pt;

            img {
                height: 50px;
                border-radius: 35px;
                width: 50px;
            }
        }

        .vessel-name {
            float: left;
            font-size: 14pt;
            width: 70%;
            height: 50%;
        }

        .vessel-number {
            float: left;
            font-size: 9pt;
            height: 50%;
            width: 70%;
        }
    }

    .title .sub-header {
        padding-top: 8px;
        text-transform: uppercase;
        font-size: 11pt;
        height: 20px;
        float: left;
        width: 100%;
    }

    .service-request {

        .pending-service-request {
            .pending-request-header {
                width: 120%;
                height: 50px;
                border-bottom: 1px solid grey;
                margin-left: -35px;
                line-height: 50px;
                color: red;
                text-align: left;
                padding-left: 20px;
            }
        }

        .remaining-service-request{
            .remaining-request-header{
                width: 120%;
                height: 50px;
                border-bottom: 1px solid grey;
                margin-left: -35px;
                line-height: 50px;
                color: blue;
                text-align: left;
                padding-left: 20px;
            }
        }

        .service-request-details {
            margin-right: -35px;
            margin-left: -35px;

            .row-details {
                border-bottom: 1px solid rgb(197, 197, 197);

                .general-description {
                    text-align: left;
                }

                .specific-details {
                    width: 100%;
                    height: 100%;
                    font-size: 10pt;
                    padding-top: 5px;
                    font-family: sans-serif;

                    .request-timestamp {
                        width: 50%;
                        height: 100%;
                        float: left;
                        text-align: left;
                        color: #bdbcbc;
                    }

                    .request-status {
                        width: 50%;
                        height: 100%;
                        float: left;
                        text-align: right;
                        color: red;
                    }

                    .request-status-progress {
                        width: 50%;
                        height: 100%;
                        float: left;
                        text-align: right;
                        color: rgb(255, 123, 0);
                    }
                }
            }

            .request-button {
                background: #747070;
                border: none;
                font-family: sans-serif;
                font-size: 11pt;
                border-radius: 2px;
                padding: 5px 10px;
                color: white;
                text-transform: uppercase;
            }
        }
    }
}