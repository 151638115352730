.conatiner {
    padding-top: 2rem;
}
.calender-container {
    padding-top: 1rem;
}
.add-icn {
    width: 50px;
    height: 50px;  
    color: rgba(255, 99, 2, 0.82);
    position: fixed;
    bottom: 10pt;
    left: 50%;
    transform: translate(-50%, 0px);
}
.head-icn {
    width: 30px;
    height: 30px;
    color:rgba(255, 99, 2, 0.82)
}
.event-list-wrpr{   
    padding: 2rem;
    height: calc(100vh - 100pt);
    overflow: scroll;
}
.event-edit-wrpr {
    padding: 1.5rem 2rem;
}
.list-item {
    padding-bottom: 1rem;
    border-bottom: 1px solid;
    margin-top: 1rem;
    text-align: justify;
}
.select-icon {
    height: 23px;
    width: 23px;
    color: rgba(255, 99, 2, 0.82);
}
.css-1ukldqf-MuiSvgIcon-root-MuiStepIcon-root.Mui-active, .MuiStepIcon-root.MuiStepIcon-completed{
    color: rgba(255, 99, 2, 0.82) !important;
}
.sts-txt {
    float: left;
    line-height: 1rem;
}
.sts-time {
    float: right;
    position: absolute;
    width: 142pt;
    height: 20pt;
    right: -.2rem;
    bottom: .5rem;
    svg {
        position: absolute;
        top: 1.3rem;
        right: 0rem;
    }
    input {
        margin-right: .2rem;
    }
}
.clk-icn {
    margin-bottom: .2rem;
}
.react-time-picker__wrapper {
border: none !important;
}
.react-time-picker__clear-button {
    display: none;
}
.page-head {
    text-align: center;
    color: #2546b1;
   
}
.head-txt h5{
    font-size: 17pt;
}
.save-btn {
    position: fixed;
    right: 23pt;
    top: 22pt;
    font-size: 14pt;
    border: none;
    background: none;   
    color: rgba(255, 99, 2, 0.82);
    outline: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    &:disabled {
        opacity: .5;
    }
}
.MuiStepper-root {
    padding: 2rem 0rem !important;
    textarea {
        width: 105%
    }

}
.date-wrapper {
    overflow: hidden;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    right: 2rem
}
.sub-txt {
    text-align: left;
    margin-top: 2rem;
    padding-left: 3rem !important;
    color: #224691;
}
.date-txt {
    margin-top: 1rem;
    color: #224691;
}
.item-txt {    
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    letter-spacing: 0.00938em;
}
.css-1o2jng6-MuiOutlinedInput-notchedOutline {
   border: none  !important;
}
.css-16ubnlw-MuiStepLabel-labelContainer {
    position: relative;
}
.MuiStepContent-root {
    position: relative;
    .tick-icn {
     position: absolute;
     right: 0rem;
     width: 44pt;
     height: 20pt;
     color: rgba(255, 99, 2, 0.82);    
    }
    .cancel-icn {
        position: absolute;
        right: 2.3rem;
        width: 44pt;
        height: 20pt;
        color: rgba(255, 99, 2, 0.82);
       
       }
    
}

.vertical-timeline::before {
background: #adb5bd !important;
width: 1pt;
}
.vertical-timeline-element-content {
    -webkit-box-shadow: 1px 1px 0px 2px #ddd;
    box-shadow: 0 3px 0 #ddd;
    border: 1px solid #ccc;
    border-radius: .4em;
    height: 50pt;
    padding: .5rem;
}

.vertical-timeline-element-content-arrow {
    border-right: 10px solid #ccc
}
.timeIcon {
    background: #fff;
    color: #224691;
    font-size: 10pt;
    padding: 0.3rem;
    border: none;
    box-shadow: none;
}
.content-scroll {
    height: calc(100vh - 200pt);
    overflow-y: scroll;
}

.event-status{
    color:#868686;
    font-size: 10pt;
    margin-top: 0.3rem;
    text-align: initial;
    padding-left: 0.6rem;
    .time-gap {
        text-align: right;
        padding-right: 1.5rem;
    }
}
.list-event-name-row {
    text-align: initial;
    padding-left: 0.5rem;
}
.center {
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}