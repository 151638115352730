.container {
    display: flex;
    width: 100%;
    background: inherit;
}

.buttonWrapper {
    display: flex;
    align-items: flex-end;
    z-index: 2;
    background: inherit;
}

.button {
    border: none;
    text-decoration: none;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 5px;
    display: none
}

.dateListScrollable {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    margin: 2px 0 2px -40px;
    -webkit-overflow-scrolling: touch;
}

.dateListScrollable::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}

.monthContainer {
    display: flex;
    flex-direction: column;
}

.monthYearLabel {
    align-self: center;
    z-index: 3;
    font-size: 12pt;
    font-weight: bold;
    position: sticky;
    top: 0;
    left: 45%;
    width: max-content;
    margin: 0 14px 10px 0;
  /* font-family: Avenir; */
}

.dateDayItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0 0 0 5px;
    width: 40pt;
    height: 45pt;
    flex-shrink: 0;
    border-radius: 12pt;
    box-shadow: 0 10px 12px -6px #f4f1f1;
    border: 1px solid #e9ecef;
    color: #868686;
    padding-top: .5rem;
}

.daysContainer {
    display: flex;
    z-index: 1;
}

.dayLabel {
    font-family: Avenir;
    text-transform: uppercase;
    font-size: 12px;
    /* color: #868686  */
}

.dateLabel {
    /* font-family: Avenir, Heavy; */
    font-size: 19pt;
    line-height: 1;
    font-weight: 600;
     /* color: #868686;  */
}